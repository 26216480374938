<template>
  <div>
    <div>
      <!-- Header section component -->
      <k-header-section
        header-text="Credit Application"
        :total-items-count="itemsCount"
        :current-page-count="currentPageCount"
        item-label="credit applications"
        :is-enable-create="false"
      />
    </div>
    <div class="table-padding">
      <div class="filter-sorting-container">
        <k-search-bar-section
          placeholder="Search By Application Id, Retailer..."
          hide-sort-option
          hide-filter-option
          hide-column-edit-option
          @on-search="updateSearch"
        />
        <!-- DISABLED TEPORARLY - ENABLE IT ONCE IMPLEMENTD BACKEND -->
        <!-- <multiple-filter
          :filter-fields="filterFields"
          @on-apply-filter="onApplyFilter"
        /> -->
      </div>
      <div
        v-if="loading"
        class="spinner-body"
      >
        <b-spinner />
      </div>
      <div
        v-else
        class="table-responsive"
      >
        <p-table
          class="transaction-table"
          :fields="tableFields"
          :items="tableItems"
        >
          <template #emptyfiltered>
            <p
              class="h2 d-flex table-empty-message justify-content-center align-items-center gap-2"
            >
              <b-icon
                icon="exclamation-circle"
                scale="1"
              />
              <span> No Items Found </span>
            </p>
          </template>
          <template #empty>
            <p
              class="h2 d-flex table-empty-message justify-content-center align-items-center gap-2"
            >
              <b-icon
                icon="exclamation-circle"
                scale="1"
              />
              <span> No Filtered Items </span>
            </p>
          </template>
          <template #cell(createdAt)="data">
            {{ formattedDate(data.item.createdAt) }}
          </template>
          <template #cell(status)="data">
            <span class="text-capitalize"> {{ data.item.status }}</span>
          </template>
          <template #cell(creditAmount)="data">
            {{ formatCurrency(data.item.creditAmount, data.item.currency) }}
          </template>
          <template #cell(_id)="data">
            <b-link
              :to="{name: ROUTES.CREDIT_APPLICATION.DETAIL.name , params: {id: data.item._id}}"
              class="text-decoration-none"
            >
              {{ data.item._id }}
            </b-link>
          </template>
        </p-table>
      </div>
      <k-table-pagination
        :total-items="itemsCount"
        @emit-current-page="updateCurrentPage"
        @emit-per-page="updatePerPage"
      />
    </div>
  </div>
</template>

<script>
import { formattedDate } from '@/@core/utils/format'
import { apiToastError } from '@/@core/utils/toast'
import { ROUTES } from '@/constants'
import { KSearchAndSortMixin } from '@/mixins/k-searbar-section.mixin'
import { PTablePaginationMixin } from '@/mixins/p-table.mixin'
import { FETCH_CREDIT_APPLICATION_LIST } from '@/store/modules/credit-application.module'
import { getCreditApplicationFields } from '@/table-fields-constants'
import {
  KHeaderSection,
  KSearchBarSection,
  KTablePagination,
  PTable,
} from '@kingpin-global/kingpin-ui'
import { utils } from '@kingpin-global/kingpin-utils-frontend'
import { BLink } from 'bootstrap-vue'

const { formatCurrency } = utils

export default {
  name: 'TransactionList',
  components: {
    PTable,
    KTablePagination,
    KHeaderSection,
    BLink,
    KSearchBarSection,
  },
  mixins: [KSearchAndSortMixin, PTablePaginationMixin],
  data() {
    return {
      tableItems: [],
      tableFields: [],
      filteredItems: [],
      itemsCount: 0,
      sortBy: '',
      searchText: '',
      currentPage: 1,
      perPage: 10,
      loading: true,
      currentPageCount: 0,
      isDesc: false,
      formattedDate,
      formatCurrency,
      ROUTES,
    }
  },
  computed: {
    creditApplicationFields() {
      return getCreditApplicationFields()
    },
    sortFields() {
      return this.creditApplicationFields.filter(
        field => !['retailerName', 'contactName', '_id'].includes(field.key),
      )
    },
  },
  created() {
    this.initPageContent()
  },
  methods: {
    async initPageContent() {
      const queryParams = {
        page: this.currentPage - 1,
        limit: this.perPage,
        search: this.searchText,
      }
      if (this.sortBy) {
        queryParams.sortBy = this.sortBy
        queryParams.asc = !this.isDesc
      }
      // DISABLED TEPORARLY - ENABLE IT ONCE IMPLEMENTD BACKEND
      // if (Object.values(this.filterQuery).length) {
      //   queryParams.filter = JSON.stringify(this.filterQuery)
      // }
      await this.$store
        .dispatch(FETCH_CREDIT_APPLICATION_LIST, queryParams)
        .then(res => {
          this.setTableData(res.data.data.creditApplicationList, res.data.data.creditApplicationCount)
          this.getTableFields(this.creditApplicationFields)
          // DISABLED TEPORARLY - ENABLE IT ONCE IMPLEMENTD BACKEND
          // this.setFilterFields(res.filter, this.creditApplicationFields)
          this.loading = false
        })
        .catch(err => {
          apiToastError(err)
          this.loading = false
        })
    },
    setTableData(items, count) {
      this.tableItems = []
      items.forEach(item => {
        const tableItem = {}
        tableItem.contactName = item.user?.firstName || ''
        tableItem.retailerName = item.user?.entityName || ''
        tableItem.paymentTerms = item.paymentTerms
        tableItem.creditAmount = item.creditAmount
        tableItem.status = item.status
        tableItem.createdAt = item.createdAt
        tableItem._id = item._id
        tableItem.securityDocumentType = item.securityDocumentType
        tableItem.currency = item.currency
        this.tableItems.push(tableItem)
      })

      this.itemsCount = count
      this.updateFilteredItemAndPaginationParams(this.items)
      this.getCurrentPageCount()
    },
    getTableFields(fields) {
      this.tableFields = fields.filter(field => field.value)
    },
  },
}
</script>
